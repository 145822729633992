<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <IconWarning slot="start" />
        <h5 slot="start">Possible PHI Identifier Detected</h5>
        <ion-button slot="end" mode="ios" @click="closeModal(null)" expand="full" color="primary" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid class="margin-top-ten">
        <ion-row>
          <ion-col>
            It looks like this message may have contained one or more unique identifiers that could compromise PHI. Please consider sending such info via Secure Chat to help maintain your patient's privacy.
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <!-- {{phiServiceResponse.entries[0]}} -->
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid class="margin-top-twenty">
        <ion-row>
          <ion-col v-if="phiServiceResponse">
            <ion-card class="prime-card-info" v-for="(entry, key) in phiServiceResponse.entries" :key="key">
              <ion-card-content>
                <ion-grid>
                  <ion-row class="ion-align-items-center">
                    <ion-col>
                      <span class="text-bold">{{ entry.text }}</span>
                    </ion-col>
                    <ion-col size="5" class="divider">
                      <div><span class="text-bold">Type: </span>{{ entry.type }}</div>
                      <div>
                        <!-- <span class="text-bold">Certainty: </span>{{ parseFloat(entry.score).toFixed(2)+"%" }} -->
                        <span class="text-bold">Certainty: </span>{{ transformPercent(entry.score) }}
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row>
          <ion-col>
            <button @click="startSecureChat()" class="prime-button button-secondary button-block">
              Start Secure Chat
              <ion-icon name="md-lock"></ion-icon>
            </button>
          </ion-col>
          <ion-col>
            <button @click="closeModal()" class="prime-button button-primary button-block">
              Close
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { warning, lock } from "ionicons/icons";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events";
import IconWarning from "@/components/Global/Icons/IconWarning";

addIcons({
  "ios-warning": warning.ios,
  "md-lock": lock.md
});

export default {
  name: "ModalPHIDetails",
  components: {
    IconWarning
  },
  data() {
    return {
      phiServiceResponse: undefined,
      PHIid: undefined
    };
  },
  methods: {
    closeModal: function() {
      this.$ionic.modalController.dismiss({ description: null });
    },
    startSecureChat: function() {
      EventBus.$emit("startSecureChat");
      this.closeModal();
    },
    getPHIDetails: async function() {
      if (this.PHIid) {
        const method = "get";
        const path = document.config.phiDetectionService + this.PHIid;
        try {
          const result = await httpSend({
            path,
            method,
            authToken: true
          });
          this.phiServiceResponse = result.data;
        } catch (error) {
          khanSolo.log(error);
        }
      }
    },
    transformPercent: function(num) {
      var autobot = num * 100;
      return autobot.toFixed(2) + "%";
    }
  },
  created() {
    this.getPHIDetails();
  }
};
</script>

<style scoped>
ion-footer {
  padding: 20px;
}
ion-footer ion-col:first-child {
  padding-right: 5px;
}
ion-footer ion-col:not(:first-child) {
  padding-left: 5px;
}
</style>
