<template>
  <div v-dompurify-html="processedMessage"></div>
</template>
<script>
export default {
  name: "ChatMessage",
  props: {
    line: {
      required: true,
      type: String
    }
  },
  computed: {
    processedMessage() {
      const linkRegex = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})*([-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])?/gim;
      const formatNewLine = this.line.replace(/\n/g, "<br />");
      const returnMessage = formatNewLine.replace(linkRegex, message => {
        const filteredMessage = /^https?/i.test(message) ? message : "//" + message;

        return message.link(filteredMessage);
      });
      return returnMessage;
    }
  }
};
</script>
