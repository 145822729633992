import { render, staticRenderFns } from "./ModalPHIDetails.vue?vue&type=template&id=fd6fc3da&scoped=true"
import script from "./ModalPHIDetails.vue?vue&type=script&lang=js"
export * from "./ModalPHIDetails.vue?vue&type=script&lang=js"
import style0 from "./ModalPHIDetails.vue?vue&type=style&index=0&id=fd6fc3da&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd6fc3da",
  null
  
)

export default component.exports