<template>
  <div class="message-timestamp" v-if="message.source == 'system'">
    LumerisEngage Automated System -
    {{ message.created_at | moment(timestampFormat) }}
    {{ messageChannel ? " - via " + messageChannel : "" }}
    <IconSms v-if="messageChannel == 'Text Message'" class="attribution-channel-icon icon-small" />
    <IconLock v-if="messageChannel == 'Secure Chat'" class="attribution-channel-icon icon-small" />
    <IconBugOutline v-if="messageChannel == 'Dbugga Text Message'" class="attribution-channel-icon icon-small" />
  </div>
  <div class="message-timestamp" v-else>
    <span v-if="showMessageAttribution" class="attribution-name">
      <span v-if="isSystem">LumerisEngage</span>
      <span v-else>{{ message.title | formatDisplayName(message.first_name, message.last_name, message.suffix) }}</span>
      -
    </span>
    {{ message.created_at | moment(timestampFormat) }}
    {{ messageChannel ? " - via " + messageChannel : "" }}
    <IconSms v-if="messageChannel == 'Text Message'" class="attribution-channel-icon icon-small" />
    <IconLock v-if="messageChannel == 'Secure Chat'" class="attribution-channel-icon icon-small" />
    <IconBugOutline v-if="messageChannel == 'Dbugga Text Message'" class="attribution-channel-icon icon-small" />
    <button v-if="showPHIIndicator" @click="showPHIModal(message.phi_detect_log_id)" class="prime-button button-small-warning">
      Possible PHI
    </button>
    <div v-if="actions" class="message-actions cursor-pointer" @click="showActions">
      <ion-icon color="primary" name="ios-more" />
    </div>
  </div>
</template>
<script>
import ModalPHIDetails from "@/components/Chat/ModalPHIDetails";
import { addIcons } from "ionicons";
import { warning, more } from "ionicons/icons";
import IconSms from "@/components/Global/Icons/IconSms";
import IconLock from "@/components/Global/Icons/IconLock";
import IconBugOutline from "@/components/Global/Icons/IconBugOutline";
import utils from "@/utils/messageUtils";

addIcons({
  "ios-warning": warning.ios,
  "ios-more": more.ios
});

export default {
  name: "ChatInfo",
  components: {
    IconSms,
    IconLock,
    IconBugOutline
  },
  props: {
    actions: Object,
    message: Object,
    direction: String,
    timestamp: { type: String, default: "exact" }
  },
  methods: {
    async showPHIModal(PHIid) {
      const modalProperties = {
        mode: "ios",
        component: ModalPHIDetails,
        componentProps: {
          data: {
            PHIid: PHIid
          },
          propsData: {
            PHIid: PHIid
          }
        }
      };
      const modal = await this.$ionic.modalController.create(modalProperties);

      return modal.present();
    },
    showActions(ev) {
      if (!this.actions || !this.actions.component) {
        return;
      }

      this.$ionic.popoverController
        .create({
          component: this.actions.component,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm",
          componentProps: {
            data: { events: this.actions.events || {} }
          }
        })
        .then(p => p.present());
    }
  },
  computed: {
    timestampFormat() {
      if (this.timestamp === "relative") {
        return "from";
      }
      return "M/D/YYYY - h:mmA";
    },
    messageChannel() {
      return utils.getChannel(this.message);
    },
    showPHIIndicator() {
      return this.message.phi_detect_log_id && this.isProfessional;
    },
    showMessageAttribution() {
      return !(this.isPatient && this.direction === "outgoing");
    },
    isSystem() {
      return this.message.last_name === "SYSTEM";
    }
  }
};
</script>

<style>
.message-timestamp {
  font-size: 10px;
  color: var(--ion-color-medium-shade);
  font-weight: 500;
}

.message-timestamp .attribution-name span {
  font-weight: 600;
  color: var(--ion-text-color);
}

.message-timestamp .attribution-channel-icon {
  height: 14px;
  width: auto;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 3px;
}
.message-timestamp .attribution-channel-icon:last-child {
  margin-right: 0;
}

.message-actions {
  display: inline-block;
  height: 13px;
  margin-left: 2px;
}

.message-actions ion-icon {
  font-size: 18px;
  vertical-align: middle;
}

.message-actions ion-icon:hover {
  fill: var(--ion-color-primary);
}
</style>
