<template>
  <ion-list mode="ios">
    <ion-item lines="full" @click="openModal(viewMessageModal, 'Conversation', image.interactionId)" class="cursor-pointer">
      <IconChatBubble class="icon-small margin-right-ten" />
      <ion-label color="dark" mode="ios">Go To Conversation </ion-label>
    </ion-item>
    <ion-item lines="full" @click="downloadImage(image.url)" class="cursor-pointer">
      <IconDownload class="icon-small margin-right-ten" />
      <ion-label color="dark" mode="ios">Download Image</ion-label>
    </ion-item>
    <ion-item v-if="image.hidden" lines="full" @click="toggleVisibility(image, false)" class="cursor-pointer">
      <IconEye class="icon-small margin-right-ten" />
      <ion-label v-if="image.hidden" color="dark" mode="ios">Unmark as Sensitive</ion-label>
    </ion-item>
    <ion-item v-else lines="full" @click="toggleVisibility(image, true)" class="cursor-pointer">
      <IconEyeHidden class="icon-small margin-right-ten" />
      <ion-label color="dark" mode="ios">Mark as Sensitive</ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import IconEyeHidden from "@/components/Global/Icons/IconEyeHidden";
import IconEye from "@/components/Global/Icons/IconEye";
import IconDownload from "@/components/Global/Icons/IconDownload";
import ModalViewConvo from "@/components/Chat/ModalViewConvo";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events.js";
import store from "@/store";

export default {
  name: "PopoverImageOptions",
  components: {
    IconChatBubble,
    IconDownload,
    IconEyeHidden,
    IconEye
  },
  data() {
    return {
      viewMessageModal: ModalViewConvo
    };
  },
  methods: {
    toggleVisibility(image, isSensitive) {
      const method = "post";
      const path = document.config.convoAttachments + "sensitive/" + image.id;
      const payload = {
        sensitive: isSensitive
      };

      httpSend({ path, payload, method, authToken: true })
        .then(() => {
          EventBus.$emit("refresh-recent-images", image);
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not get Recent Images. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.$ionic.popoverController.dismiss();
        });
    },
    downloadImage(url) {
      if (url) {
        const method = "get";
        const t = url;
        const path = t; // t.substring(0, t.lastIndexOf("/"));
        const tfile = t.substring(t.lastIndexOf("/") + 1);
        const responseType = "blob";
        const headers = {
          "Cache-Control": "no-cache"
        };

        httpSend({ path, method, headers, authToken: false, responseType })
          .then(response => {
            // force file download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", tfile);
            document.body.appendChild(link);
            link.click();
            // end force file download
          })
          .catch(error => {
            this.$ionic.toastController
              .create({
                header: "The image failed to download properly. Please try again later.",
                message: error,
                duration: 7000,
                position: "top"
              })
              .then(m => m.present());

            khanSolo.log(error);
            this.isDownloading = false;
          })
          .finally(() => {
            this.$ionic.popoverController.dismiss();
          });
      } else {
        this.$ionic.toastController
          .create({
            header: "The image failed to download properly. Please try again later.",
            duration: 7000,
            position: "top"
          })
          .then(m => m.present());
      }
    },
    openModal(componentName, modalTitle, id) {
      this.$ionic.popoverController.dismiss();
      this.$ionic.modalController
        .create({
          component: componentName,
          mode: "ios",
          componentProps: {
            propsData: {
              title: modalTitle,
              interactionId: id || null,
              currentPatient: store.state.chatProfessional.currentPatient
            }
          }
        })
        .then(m => m.present());
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
</style>
