import { render, staticRenderFns } from "./ModalViewConvo.vue?vue&type=template&id=2f97f158"
import script from "./ModalViewConvo.vue?vue&type=script&lang=js"
export * from "./ModalViewConvo.vue?vue&type=script&lang=js"
import style0 from "./ModalViewConvo.vue?vue&type=style&index=0&id=2f97f158&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports