<template>
  <div class="column-stretch-wrap pad-bottom-twenty">
    <div class="column-stretch-header">
      <ion-row>
        <ion-col></ion-col>
        <ion-col size="auto">
          <button class="prime-button button-secondary margin-top-ten margin-right-ten" mode="ios" @click="closeModal(null)"><ion-icon class="icon-large margin-top-five" name="md-close"></ion-icon></button>
        </ion-col>
      </ion-row>
    </div>
    <div class="column-stretch-body">
      <ion-row class="center-center">
        <ion-col></ion-col>
        <ion-col size="auto">
          <div class="img-wrapper" :class="{ 'img-wrap-hidden': showOrHide }">
            <div v-if="showOrHide" class="text-center">
              <IconEyeHidden class="icon-eye" />
              <h3>This image has been marked as sensitive</h3>
              <ion-row>
                <ion-col size="4"></ion-col>
                <ion-col>
                  <button @click="viewAnyway" class="prime-button button-secondary margin-top-thirty button-block">
                    View Anyway
                  </button>
                </ion-col>
                <ion-col size="4"></ion-col>
              </ion-row>
            </div>
            <img class="prime-modal-image" v-else :src="url" alt="Patient photo" />
          </div>
        </ion-col>
        <ion-col></ion-col>
      </ion-row>
    </div>
    <div class="column-stretch-footer">
      <ion-row>
        <ion-col></ion-col>
        <ion-col size="auto" class="margin-right-ten">
          <button class="prime-button button-block button-tertiary double" @click="toggleVisibility(image)" :class="{ 'button-pending': isHiding }">
            <ion-spinner name="lines" class="spinner-small"></ion-spinner>
            <ion-row v-if="!isHiding" class="ion-align-items-center">
              <ion-col></ion-col>
              <ion-col size="auto">
                <span class="margin-right-five">{{ isHidden ? "Unmark as Sensitive" : "Mark as Sensitive" }}</span>
              </ion-col>
              <ion-col size="auto">
                <span class="valign">
                  <IconEye v-if="isHidden" class="icon-small" />
                  <IconEyeHidden v-else class="icon-small" />
                </span>
              </ion-col>
              <ion-col></ion-col>
            </ion-row>
          </button>
        </ion-col>
        <ion-col size="auto">
          <button class="prime-button button-block button-tertiary double" @click="downloadFile" :class="{ 'button-pending': isDownloading }">
            <ion-spinner name="lines" class="spinner-small"></ion-spinner>
            <ion-row v-if="!isDownloading" class="ion-align-items-center">
              <ion-col></ion-col>
              <ion-col size="auto" class="margin-right-five"><span>Download Image</span></ion-col>
              <ion-col size="auto"><IconDownload v-if="!isDownloading" class="icon-small"/></ion-col>
              <ion-col></ion-col>
            </ion-row>
          </button>
        </ion-col>
        <ion-col></ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { close } from "ionicons/icons";
import IconDownload from "@/components/Global/Icons/IconDownload";
import store from "@/store";
import { send as httpSend } from "@/services/Api";
import IconEyeHidden from "@/components/Global/Icons/IconEyeHidden";
import IconEye from "@/components/Global/Icons/IconEye";
import { EventBus } from "@/services/Events.js";

addIcons({
  "md-close": close.md
});

export default {
  name: "ModalImage",
  components: {
    IconDownload,
    IconEyeHidden,
    IconEye
  },
  computed: {
    dbuggaMode() {
      return store.getters["dbugga/dbuggaMode"];
    },
    showOrHide() {
      return this.isHidden && this.isSoftHidden;
    }
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    isHidden: {
      type: Boolean,
      default: false
    },
    image: {
      type: Object
    }
  },
  data() {
    return {
      isDownloading: false,
      isHiding: false,
      isSoftHidden: this.isHidden
    };
  },
  methods: {
    closeModal() {
      this.$ionic.modalController.dismiss();
    },
    viewAnyway() {
      this.isSoftHidden = false;
    },
    toggleVisibility(image) {
      this.isHiding = true;
      const method = "post";
      const path = document.config.convoAttachments + "sensitive/" + this.id;
      const payload = {
        sensitive: !this.isHidden
      };

      httpSend({ path, payload, method, authToken: true })
        .then(() => {
          this.isHidden = !this.isHidden;
          this.isSoftHidden = true;
          EventBus.$emit("refresh-recent-images", image);
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not get Recent Images. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.isHiding = false;
        });
    },
    downloadFile() {
      if (this.url) {
        this.isDownloading = true;
        const method = "get";
        const t = this.url;
        const path = t; // t.substring(0, t.lastIndexOf("/"));
        const tfile = t.substring(t.lastIndexOf("/") + 1);
        const responseType = "blob";
        const headers = {
          "Cache-Control": "no-cache"
        };

        httpSend({ path, method, headers, authToken: false, responseType })
          .then(response => {
            this.isDownloading = false;
            // force file download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", tfile);
            document.body.appendChild(link);
            link.click();
            // end force file download
          })
          .catch(error => {
            this.$ionic.toastController
              .create({
                header: "The image failed to download properly. Please try again later.",
                message: error,
                duration: 7000,
                position: "top"
              })
              .then(m => m.present());
            khanSolo.log(error);
            this.isDownloading = false;
          });
      } else {
        this.$ionic.toastController
          .create({
            header: "The image failed to download properly. Please try again later.",
            duration: 7000,
            position: "top"
          })
          .then(m => m.present());
      }
    }
  }
};
</script>

<style scoped>
.prime-modal {
  margin-top: 10vh;
}
.controls {
  text-align: center;
  margin-top: 2rem;
}

.controls button {
  max-width: 31%;
}

button.modal-act,
a.modal-act {
  width: 30%;
  margin: 10px;
}

/* .img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
} */

.img-wrap-hidden {
  background-color: var(--ion-color-medium);
  max-width: 600px;
  padding: 30px;
}

img {
  max-height: 600px;
  display: block;
  margin: 0 auto;
}

.closeIco {
  width: 40px;
  z-index: 10480;
  color: rgb(255, 255, 255);
  font-size: 45px;
  margin: 5px 5px;
}
.icon-eye {
  height: 100px;
  fill: #707a85;
}
.prime-modal-image {
  max-height: 70vh;
  max-width: 85vw;
}

.prime-button.button-tertiary {
  max-width: 600px;
}
</style>
