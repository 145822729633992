<template>
  <div class="prime-modal">
    <ion-header class="prime-view-past-conversation-header">
      <ion-toolbar>
        <IconChatBubble slot="start" class="icon-small" />
        <h5 slot="start">{{ title }}</h5>
        <ion-button slot="end" mode="ios" @click="closeModal(null)" expand="full" color="primary" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-view-past-conversation-container">
      <MessagePanelSingleInteraction class="view-conversation-message-panel pad-bottom-twenty" :title="title" :interactionId="interactionId" :currentPatient="currentPatient" :convoChannel="'all'" :showMessageFilters="false" />
    </ion-content>
  </div>
</template>

<script>
import MessagePanelSingleInteraction from "./MessagePanelSingleInteraction";
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";

export default {
  name: "ModalViewConvo",
  props: {
    title: { type: String, default: "View Conversation" },
    interactionId: Number,
    currentPatient: Object
  },
  components: {
    MessagePanelSingleInteraction,
    IconChatBubble
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$ionic.modalController.dismiss();
    }
  }
};
</script>

<style>
.view-conversation-message-panel .active-conversation-header {
  margin-top: 15px !important;
  margin-bottom: 10px !important;
}
.view-conversation-message-panel .active-conversation-title-container {
  display: none;
}
.prime-modal ion-content.prime-view-past-conversation-container ion-footer {
  display: none;
}
.prime-modal ion-header.prime-view-past-conversation-header {
  border-bottom: 2px solid var(--ion-color-light-shade);
}
.prime-modal ion-content.prime-view-past-conversation-container {
  --background: var(--ion-modal-secondary-background);
}
.prime-modal ion-content.prime-view-past-conversation-container ion-content,
.prime-modal ion-content.prime-view-past-conversation-container ion-toolbar {
  --background: var(--ion-modal-secondary-background);
}
</style>
